/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

import './bootstrap'
import Vue from 'vue'
import VueRouter from 'vue-router'
import VueFlashMessage from 'vue-flash-message'
import ValidationProvider from './validation';
import { ValidationObserver } from 'vee-validate';
import VueCookies from 'vue-cookies';
import VueI18n from 'vue-i18n';
import i18nMessages from './locales/index';
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css';

Vue.use(VueI18n);
const i18n = new VueI18n({
    locale: 'id',
    messages: i18nMessages
});

Vue.use(VueCookies);
Vue.use(VueRouter);
Vue.use(VueFlashMessage, {
    messageOptions: {
        timeout: 3000,
    }
});

Vue.component('v-select', vSelect);

Vue.component('ValidationObserver', ValidationObserver);
Vue.component('validation-provider', ValidationProvider);

/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */

import router from './router';
import mixin from './mixin';
import App from './App'

Vue.mixin(mixin);

Vue.directive('uppercase', {
    update(el) {
        const source = el.value;
        const upper = source.toUpperCase();

        if(source !== upper) {
            el.value = upper;
            el.dispatchEvent(new Event('input', { bubbles: true }));
        }
    }
});

axios.interceptors.request.use(
    config => {
        const token = $cookies.get('auth_token');
        if(token) {
            config.headers['Authorization'] = 'Bearer ' + token;
        }
        config.headers['Content-Type'] = 'application/json';
        return config;
    },
    error => {
        return Promise.reject(error)
    });
axios.interceptors.response.use((response) => {
        return response;
    },
    function (error) {
        const originalRequest = error.config;
        if(error.response.status === 401 && error.response.data.error === 'UNAUTHENTICATED') {
            if(error.config.url !== '/api/v1/login') {
                router.push({ name: 'login', query: { r: router.currentRoute.path } }).catch(error => {});
            }
        } else {
            return Promise.reject(error);
        }
    });

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

const app = new Vue({
    el: '#app',
    router,
    i18n,
    render: h => h(App)
});
