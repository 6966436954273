import VueRouter from 'vue-router'

const routes = [
    {
        path: '/',
        name: 'dashboard',
        component: () => import('./views/Dashboard'),
    },
    {
        path: '/login',
        name: 'login',
        component: () => import('./views/Login'),
    },
    {
        path: '/products',
        name: 'product_list',
        component: () => import('./views/ProductList')
    },
    {
        path: '/products/add',
        name: 'product_add',
        component: () => import('./views/ProductCreate')
    },
    {
        path: '/products/edit/:id',
        name: 'product_edit',
        component: () => import('./views/ProductEdit'),
        props: true
    },
    {
        path: '/customers',
        name: 'customer_list',
        component: () => import('./views/CustomerList')
    },
    {
        path: '/customers/add',
        name: 'customer_add',
        component: () => import('./views/CustomerCreate')
    },
    {
        path: '/customers/edit/:id',
        name: 'customer_edit',
        component: () => import('./views/CustomerEdit'),
        props: true
    },
    {
        path: '/customers/view/:id',
        name: 'customer_view',
        component: () => import('./views/CustomerView'),
        props: true
    },
    {
        path: '/employees',
        name: 'employee_list',
        component: () => import('./views/EmployeeList')
    },
    {
        path: '/employees/add',
        name: 'employee_add',
        component: () => import('./views/EmployeeCreate')
    },
    {
        path: '/employees/edit/:id',
        name: 'employee_edit',
        component: () => import('./views/EmployeeEdit'),
        props: true
    },
    {
        path: '/employees/view/:id',
        name: 'employee_view',
        component: () => import('./views/EmployeeView'),
        props: true
    },
    {
        path: '/purchase',
        name: 'purchase_list',
        component: () => import('./views/TransactionInList'),
    },
    {
        path: '/purchase/new',
        name: 'purchase_new',
        component: () => import('./views/TransactionInCreate'),
    },
    {
        path: '/purchase/view/:id?',
        name: 'purchase_view',
        component: () => import('./views/TransactionInView'),
        props: true
    },
    {
        path: '/sales',
        name: 'sales_list',
        component: () => import('./views/TransactionOutList'),
    },
    {
        path: '/sales/new',
        name: 'sales_new',
        component: () => import('./views/TransactionOutCreate'),
    },
    {
        path: '/sales/view/:id?',
        name: 'sales_view',
        component: () => import('./views/TransactionOutView'),
        props: true
    },
    {
        path: '/sales/nfi-import/select-file',
        name: 'nfi_import_select',
        component: () => import('./views/ImportSelection'),
    },
    {
        path: '/sales/nfi-import/review',
        name: 'nfi_import_review',
        component: () => import('./views/ImportForm'),
        props: true
    },
    {
        path: '/return',
        name: 'return_list',
        component: () => import('./views/TransactionReturnList'),
    },
    {
        path: '/return/new',
        name: 'return_new',
        component: () => import('./views/TransactionReturnCreate'),
    },
    {
        path: '/return/view/:id?',
        name: 'return_view',
        component: () => import('./views/TransactionReturnView'),
        props: true
    },
    {
        path: '/report/legacy',
        name: 'report_legacy',
        component: () => import('./views/ReportLegacy'),
    },
    {
        path: '/report/stock',
        name: 'report_stock',
        component: () => import('./views/ReportStock'),
    },
    {
        path: '/channels',
        name: 'channel_list',
        component: () => import('./views/ChannelList'),
    },
    {
        path: '/channels/add',
        name: 'channel_add',
        component: () => import('./views/ChannelCreate')
    },
    {
        path: '/channels/edit/:id',
        name: 'channel_edit',
        component: () => import('./views/ChannelEdit'),
        props: true
    },
    {
        path: '/product-groups',
        name: 'product_group_list',
        component: () => import('./views/ProductGroupList'),
    },
    {
        path: '/product-groups/add',
        name: 'product_group_add',
        component: () => import('./views/ProductGroupCreate')
    },
    {
        path: '/product-groups/edit/:id',
        name: 'product_group_edit',
        component: () => import('./views/ProductGroupEdit'),
        props: true
    },
    {
        path: '/roles',
        name: 'role_list',
        component: () => import('./views/EmployeeRoleList'),
    },
    {
        path: '/roles/add',
        name: 'role_add',
        component: () => import('./views/EmployeeRoleCreate')
    },
    {
        path: '/roles/edit/:id',
        name: 'role_edit',
        component: () => import('./views/EmployeeRoleEdit'),
        props: true
    },
    {
        path: '/account/change-password',
        name: 'change_password',
        component: () => import('./views/ChangePassword')
    },
    {
        path: '/products/low-stocks',
        name: 'low_stock_product_list',
        component: () => import('./views/LowStockProductList')
    },
    {
        path: '/sales/due',
        name: 'sales_due_list',
        component: () => import('./views/DueTransactionList')
    },
    {
        path: '*',
        name: '404_page',
        component: () => import('./views/PageNotFound')
    },
];

const router = new VueRouter({
    routes,
    mode: 'history',
    // linkActiveClass: 'active'
});
router.beforeEach((to, from, next) => {
    const cookie = $cookies.get('auth_token');
    if(cookie !== null && to.name === 'login') {
        next({name: 'dashboard'});
    } else if(cookie === null && to.name !== 'login') {
        next({name: 'login'})
    } else {
        next();
    }
});

export default router;
