import { ValidationProvider, extend } from 'vee-validate';

import { required, length, max, min, min_value, integer, email } from 'vee-validate/dist/rules';

extend('required', required);
extend('length', length);
extend('max', max);
extend('min', min);
extend('min_value', min_value);
extend('integer', integer);
extend('email', email);

export default ValidationProvider;