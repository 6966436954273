export default {
    methods: {
        calculateDiscount(amount, disc1, disc2, disc3) {
            let total1 = amount - (amount * disc1 / 100.0);
            let total2 = total1 - (total1 * disc2 / 100.0);
            let total3 = total2 - (total2 * disc3 / 100.0);
            return this.roundToTwoDecimals((amount - total3));
        },
        roundToTwoDecimals(num) {
            return Math.round((num + Number.EPSILON) * 100) / 100;
        },
        formatCurrency(amount, withSymbol = false) {
            const fmt = amount.toLocaleString('id-ID', {minimumFractionDigits: 2});
            if(!withSymbol) return fmt;
            return 'Rp. ' + fmt;
        },
        formatDate(date) {
            return date.getFullYear() + '-' + String((date.getMonth()+1)).padStart(2, '0') + '-' + String(date.getDate()).padStart(2, '0');
        },
        formatIdDate(date) {
            const months = [
                'Januari',
                'Februari',
                'Maret',
                'April',
                'Mei',
                'Juni',
                'Juli',
                'Agustus',
                'September',
                'Oktober',
                'November',
                'Desember'
            ];
            return String(date.getDate()).padStart(2, '0') + ' ' + months[date.getMonth()] + ' ' + date.getFullYear();
        },
        formatIdDateTimeFromString(dateStr) {
            const date = new Date(dateStr);
            const months = [
                'Januari',
                'Februari',
                'Maret',
                'April',
                'Mei',
                'Juni',
                'Juli',
                'Agustus',
                'September',
                'Oktober',
                'November',
                'Desember'
            ];
            return String(date.getDate()).padStart(2, '0') + ' ' + months[date.getMonth()] + ' ' +
                date.getFullYear() + ' ' +
                String(date.getHours()).padStart(2, '0') + ':' +
                String(date.getMinutes()).padStart(2, '0');
        },
        formatBytes(bytes, decimals = 2) {
            if (bytes === 0) return '0 Bytes';
            const k = 1024;
            const dm = decimals < 0 ? 0 : decimals;
            const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
            const i = Math.floor(Math.log(bytes) / Math.log(k));
            return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
        },
        formatIdDateFromString(str) {
            const d = new Date(str);
            return this.formatIdDate(d);
        },
        isObjectEmpty(obj) {
            for(const prop in obj) {
                if(obj.hasOwnProperty(prop)) return false;
            }
            return true;
        }
    }
}